import { FETCH_EXPERIMENTS_SUCCESS } from '../../common/actions/fetch-experiments';

export function experiments(state = {}, action) {
  switch (action.type) {
    case FETCH_EXPERIMENTS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
